<template>
  <q-td :style="statusOptions.colStyle" class="limited-width">
    <div class="row items-center no-wrap">
      <div class="status-container">
        <runai-status :options="statusOptions" />
      </div>
      <q-btn
        @click.stop="isSchedulingMessagesMenuOpen = true"
        v-if="showPendingSchedulingMessagesIcon"
        class="q-ml-sm q-pa-none"
        icon="fa-regular fa-circle-info"
        flat
        round
        size="10px"
      >
        <workload-pending-scheduling-messages-menu
          v-if="isSchedulingMessagesMenuOpen"
          @hide="isSchedulingMessagesMenuOpen = false"
          :workload-id="workloadId"
        />
      </q-btn>
    </div>
  </q-td>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

// utils
import { getFieldContent } from "@/utils/table-format.util";
// models
import type { IStatusColOptions, ITableColumn } from "@/models/table.model";
import { RunaiStatus } from "@/components/common/runai-status/";
import { WorkloadPendingSchedulingMessagesMenu } from "@/components/workload/workload-pending-scheduling-messages-menu";
import { Phase, type Workload } from "@/swagger-models/workloads-client";

export default defineComponent({
  name: "workload-status-col",
  components: { WorkloadPendingSchedulingMessagesMenu, RunaiStatus },
  emits: ["custom-cell-event"],
  props: {
    row: {
      type: Object as PropType<Workload>,
      required: true,
    },
    column: {
      type: Object as PropType<ITableColumn>,
      required: true,
    },
  },
  data() {
    return {
      isSchedulingMessagesMenuOpen: false,
    };
  },
  computed: {
    statusOptions(): IStatusColOptions {
      return getFieldContent(this.column, this.row);
    },
    workloadId(): string {
      return this.row.id;
    },
    showPendingSchedulingMessagesIcon(): boolean {
      return this.row.phase === Phase.Pending && !!this.row.phaseReason;
    },
  },
});
</script>
<style scoped lang="scss">
.limited-width {
  max-width: 200px;
}

.status-container {
  min-width: 130px;
}
</style>

import type { IToolItem } from "@/models/workload.model";
import type { Inference } from "@/swagger-models/assets-service-client";

export enum EInferenceType {
  RunaiModel = "runai-model",
  HuggingFace = "hugging-face",
  AssetsBased = "assets-based",
  Nim = "nim",
}

export enum EHuggingFaceEnvVariableNames {
  RUNAI_MODEL = "RUNAI_MODEL",
  HF_TOKEN = "HF_TOKEN",
  RUNAI_MODEL_NAME = "RUNAI_MODEL_NAME",
}

export enum ENimEnvVariableNames {
  NimModelProfile = "NIM_MODEL_PROFILE",
  NgcApiKey = "NGC_API_KEY",
  NimCachePath = "NIM_CACHE_PATH",
  OutlinesCacheDir = "OUTLINES_CACHE_DIR",
  NimServerPort = "NIM_SERVER_PORT",
  NimJsonlLogging = "NIM_JSONL_LOGGING",
  NimLogLevel = "NIM_LOG_LEVEL",
}

export const SERVING_PORT_SERVER_PORT = 8000;

export const LLM_IMAGE_FOR_HUGGING_FACE = "vllm/vllm-openai:latest";
export const LLM_IMAGE_FOR_TGI = "ghcr.io/huggingface/text-generation-inference:latest";
export const NIM_IMAGE_PREFIX = "nvcr.io/nim/";

// old values
export const OLD_LLM_ENVIRONMENT_FOR_HUGGING_FACE = "llm-server";
export const OLD_LLM_IMAGE_FOR_HUGGING_FACE = "runai.jfrog.io/core-llm/runai-vllm";

export interface IInferenceList extends Inference {
  connections?: Array<IToolItem>;
  extendJobValues?: IExtendJobValues;
}
export interface IExtendJobValues {
  usedMemory?: string;
  usedCPUs?: string;
  gpusUtilization?: string;
  usedGpuMemory?: string;
  currentAllocatedGPUsMemoryBytes?: number;
}

import { DATA_SOURCE_ICONS_NAMES, distFrameworkIconsMap, toolTypeIconsMap } from "@/common/icons.constant";
import type { ITokenData } from "@/models/environment.model";
import {
  IMAGE_PULL_POLICY_LABELS,
  type IUIConnection,
  toolTypeOptions,
  UID_GID_SOURCE_LABELS,
} from "@/models/environment.model";
import {
  type IDrawerListItem,
  type IDrawerListItemIcon,
  type TItemDetail,
} from "@/models/workload-creation-drawer-model";
import {
  AssetKind,
  InternalConnectionType,
  GpuRequestType,
  ToolType,
  type ExtendedResource,
  type ComplianceInfo,
  type ComputeAsset,
  type Connection,
  type DatasourceListResponseEntry,
  type DistributedFramework,
  type EnvironmentAsset,
  type EnvironmentVariableOfAsset,
  type ComputeAssetSpec,
  ImagePullPolicy,
  type HostPathInstance,
  type PvcInstance,
  type NfsInstance,
  type GitInstance,
  type S3Instance,
  type ConfigMapInstance,
  type SecretInstance,
  type PVCAssetSpec,
  type PvcAccessModes,
  type S3AssetSpec,
  type ConfigMapAssetSpec,
  type NFSAssetSpec,
  type HostPathSpec,
  type GitAssetSpec,
  type SecretAssetSpec,
} from "@/swagger-models/assets-service-client";
import {
  type ExposedUrl,
  type Port,
  type RelatedUrl,
  UidGidSource,
  type ComputeFields,
  type SecurityFlatFields,
  type StorageFields,
  type UnifiedWorkload,
  type WorkspaceCreationRequest,
  type WorkspaceSpecSpec,
  type TrainingCreationRequest,
  type UnifiedPolicyInfoPerReplicaType,
} from "@/swagger-models/workloads-client";
import { fallbackDefaultIfNullOrUndefined, makeId, omit } from "../common.util";
import type {
  IUIConfigMapInstance,
  IUIEnvrionmentFields,
  IUIGitInstance,
  IUIHostPathInstance,
  IUINfsInstance,
  IUIPvcInstance,
  IUIS3Instance,
  IUISecretVolumeInstance,
  IUIStorageFields,
  IUITrainingCreationRequest,
  IUIWorkspaceCreationRequest,
  IUIWorkspaceSpec,
} from "@/models/workload.model";
import { isNullOrUndefined } from "../common.util";
import type { IUIVolume } from "@/models/data-source.model";

export const LINE_BREAK = "<br />";

export const workloadHybridUtil = {
  environmentListToDrawerItems,
  computeListToDrawerItems,
  dataSourceListToDrawerItems,
  getHybridWorkspaceModel,
  getHybridTrainingModel,
  setWorkspaceUIDefaults,
  setTrainingUIDefaults,
  getRelevantComputeFields,
  getRelevantStorageFields,
  getRelevantEnvironmentFields,
  combineWorkloadModelWithComputeDetails,
  combineWorkloadModelWithStorageDetails,
  combineWorkloadModelWithEnvironmentDetails,
  prepareWorkspaceFlatRequest,
  prepareTrainingFlatRequest,
};

function computeListToDrawerItems(computes: ComputeAsset[]): IDrawerListItem[] {
  return computes.map((compute: ComputeAsset) => ({
    name: compute.meta.name,
    id: compute.meta.id,
    kind: compute.meta.kind,
    description: compute.meta.description || "",
    icons: [{ name: "default", icon: "compute-resource-gray-new" }],
    policy: compute.compliance,
    createdAt: compute.meta.createdAt,
    lastUsed: compute.usageTimes?.lastUsedByWorkload || "",
    disabled: _isDisabled(compute.compliance),
    details: _computeResourceRawToDetails(compute),
  }));
}

function dataSourceListToDrawerItems(dataSources: DatasourceListResponseEntry[]): IDrawerListItem[] {
  return dataSources.map((ds: DatasourceListResponseEntry) => ({
    name: ds.meta.name,
    id: ds.meta.id,
    kind: ds.meta.kind,
    description: ds.meta.description || "",
    icons: _getDataSourceIcon(ds),
    policy: ds.compliance,
    createdAt: ds.meta.createdAt,
    lastUsed: ds.usageTimes?.lastUsedByWorkload || "",
    disabled: _isDisabled(ds.compliance),
    details: _dataSourceRawToDetails(ds),
  }));
}

function environmentListToDrawerItems(environments: EnvironmentAsset[]): IDrawerListItem[] {
  return environments.map((env: EnvironmentAsset) => ({
    name: env.meta.name,
    id: env.meta.id,
    kind: env.meta.kind,
    description: env.meta.description || "",
    icons: _getEnvironmentIcons(env),
    policy: env.compliance,
    createdAt: env.meta.createdAt,
    lastUsed: env.usageTimes?.lastUsedByWorkload || "",
    disabled: _isDisabled(env.compliance),
    details: _environmentRawToDetails(env),
  }));
}

function _getEnvironmentIcons(env: EnvironmentAsset): IDrawerListItemIcon[] {
  const defaultIcon: IDrawerListItemIcon = { name: "default", icon: "environment-gray-new" };

  if (!env.spec.connections?.length && !env.meta.workloadSupportedTypes?.distFramework) {
    return [defaultIcon];
  }

  const icons: Array<IDrawerListItemIcon> = [];

  const { distFramework } = env.meta.workloadSupportedTypes || {};
  if (distFramework) {
    // DistFramwork icon should be the first icon.
    icons.push({ name: distFramework, icon: distFrameworkIconsMap[distFramework] });
  }

  env.spec.connections?.forEach((connection: Connection) => {
    const toolType: string | undefined = connection.internalToolInfo?.toolType || connection.externalToolInfo?.toolType;
    icons.push({
      name: connection.name,
      icon: (toolType && toolTypeIconsMap[toolType]) || defaultIcon.icon,
    });
  });

  return icons;
}

function _getDataSourceIcon(ds: DatasourceListResponseEntry): IDrawerListItemIcon[] {
  return [
    {
      name: ds.meta.kind || "",
      icon: (DATA_SOURCE_ICONS_NAMES[ds.meta.kind as keyof object] as string) || DATA_SOURCE_ICONS_NAMES.default,
    },
  ];
}

function _isDisabled(compliance: ComplianceInfo | null | undefined): boolean {
  if (!compliance) return false;
  return !compliance.compliance;
}

function _hasValue<T>(value: T): boolean {
  return !isNullOrUndefined(value);
}

function _environmentRawToDetails(env: EnvironmentAsset): TItemDetail[] {
  const itemDetails = [];
  env.meta.description && itemDetails.push({ title: "Description", content: [env.meta.description] });
  env.spec.image && itemDetails.push({ title: "Image URL", content: [env.spec.image] });
  env.spec.imagePullPolicy &&
    itemDetails.push({
      title: "Image pull policy",
      content: [IMAGE_PULL_POLICY_LABELS[env.spec.imagePullPolicy] || ""],
    });
  env.meta.workloadSupportedTypes?.distFramework &&
    itemDetails.push(_parseFrameworkDetails(env.meta.workloadSupportedTypes?.distFramework));
  env.spec.connections && itemDetails.push(..._parseConnectionsToDetails(env.spec.connections || []));

  (env.spec.command || env.spec.args) &&
    itemDetails.push({
      title: "Command and arguments",
      content: _parseCommandAndArgsToDetails(env.spec.command || "", env.spec.args || ""),
    });
  env.spec.environmentVariables?.length &&
    itemDetails.push({
      title: "Environment variables",
      content: _parseEnvVarsToDetailsContent(env.spec.environmentVariables || []) || "",
    });
  env.spec.workingDir && itemDetails.push({ title: "Container's working directory ", content: [env.spec.workingDir] });
  env.spec.uidGidSource &&
    itemDetails.push(
      _parseUidGidSource(env.spec.uidGidSource, env.spec.runAsUid, env.spec.runAsGid, env.spec.supplementalGroups),
    );
  env.spec?.capabilities?.length &&
    itemDetails.push({
      title: "Additional Linux capabilities for the container",
      content: [env.spec.capabilities?.join(", ")],
    });
  return itemDetails;
}

function _parseCommandAndArgsToDetails(command: string, args: string) {
  const output: string[] = [];
  if (command) output.push(`Command: ${command}`);
  if (args) output.push(`Arguments: ${args}`);
  return output;
}

function _parseEnvVarsToDetailsContent(envVars: EnvironmentVariableOfAsset[]) {
  const output: string[] = [];
  return envVars.reduce((acc, envVar) => {
    const { name, value, credential } = envVar;
    acc.push(`Name: ${name}`);
    if (!credential) {
      acc.push(`Source: Custom`);
      acc.push(`Value: ${value}`);
    } else {
      acc.push(`Source: Credential`);
    }
    acc.push(LINE_BREAK);
    return acc;
  }, output);
}

function _parseConnectionsToDetails(connections: Connection[]): TItemDetail[] {
  return connections.map((c: Connection, index: number) => {
    const detail: TItemDetail = {
      title: c.name,
      icon: _getConnectionIcon(c),
      noSeperator: index !== connections.length - 1,
      content: _connectionContent(c),
    };
    return detail;
  });
}

function _connectionContent(c: Connection): string[] {
  const output: string[] = [];

  if (c.isExternal) {
    c.externalToolInfo?.externalUrl && output.push(`External URL: ${c.externalToolInfo?.externalUrl || ""}`);
  } else {
    c.internalToolInfo?.connectionType && output.push(`Connection type: ${c.internalToolInfo?.connectionType || ""}`);
    c.internalToolInfo?.containerPort && output.push(`Container port: ${c.internalToolInfo?.containerPort || ""}`);
    c.internalToolInfo?.externalUrlInfo?.externalUrl &&
      output.push(`Custom URL: ${c.internalToolInfo?.externalUrlInfo?.externalUrl || ""}`);
    c.internalToolInfo?.servingPortInfo?.protocol &&
      output.push(`Protocol: ${c.internalToolInfo?.servingPortInfo?.protocol || ""}`);
  }

  return output;
}

function _getConnectionIcon(c: Connection): string {
  const toolType = c.isExternal ? c.externalToolInfo?.toolType : c.internalToolInfo?.toolType;
  return toolTypeOptions.find((t) => t.value === toolType)?.icon || "default";
}

function _parseFrameworkDetails(distFramework: DistributedFramework): TItemDetail {
  return {
    title: `${distFramework} framework`,
    icon: distFrameworkIconsMap[distFramework] || "",
    content: [],
  };
}

function _parseUidGidSource(
  uidGidSource: UidGidSource,
  uid?: number | null,
  gid?: number | null,
  suppGroups?: string | null,
): TItemDetail {
  const content = [UID_GID_SOURCE_LABELS[uidGidSource]];

  if (uidGidSource === UidGidSource.Custom) {
    uid && content.push(`User ID (UID): ${uid || ""}`);
    gid && content.push(`Group ID (GID): ${gid || ""}`);
    suppGroups && content.push(`Supplementary groups: ${suppGroups}`);
  }

  return {
    title: "Image pull policy",
    content,
  };
}

function _computeResourceRawToDetails(compute: ComputeAsset): TItemDetail[] {
  const itemDetails = [];

  _hasValue(compute.meta.description) &&
    itemDetails.push({ title: "Description", content: [compute.meta.description || ""] });

  _hasValue(compute.spec.gpuDevicesRequest) &&
    itemDetails.push({
      title: "GPU devices per pod",
      content: [`GPU devices: ${compute.spec.gpuDevicesRequest}`],
    });

  const gpuMemoryContent = _parseGpuMemory(compute.spec);
  if (gpuMemoryContent.length)
    itemDetails.push({
      title: "GPU memory per device",
      content: gpuMemoryContent,
    });

  const cpuCoreContent = _parseCpuCoreRequest(compute.spec);
  if (cpuCoreContent.length) {
    itemDetails.push({
      title: "CPU compute per pod",
      content: cpuCoreContent,
    });
  }

  const cpuMemoryContent = _parseCpuMemory(compute.spec);
  if (cpuMemoryContent.length) {
    itemDetails.push({
      title: "CPU memory per pod",
      content: cpuMemoryContent,
    });
  }

  compute.spec.largeShmRequest &&
    itemDetails.push({
      title: "Increase shared memory size",
      content: ["Enabled"],
    });

  compute.spec?.extendedResources?.length &&
    itemDetails.push({
      title: "Extended resource(s)",
      content: _parseExtendedResourcesToDetailsContent(compute.spec.extendedResources),
    });

  return itemDetails;
}

function _parseGpuMemory(computeSpec: ComputeAssetSpec): string[] {
  const { gpuRequestType, gpuMemoryRequest, gpuMemoryLimit, gpuPortionRequest, gpuPortionLimit } = computeSpec;

  const output: string[] = [];
  if (gpuRequestType === GpuRequestType.Portion) {
    _hasValue(gpuPortionRequest) && output.push(`Request: ${(gpuPortionRequest ?? 0) * 100}%`);
    _hasValue(gpuPortionLimit) && output.push(`Limit: ${(gpuPortionLimit ?? 0) * 100}%`);
  } else if (gpuRequestType === GpuRequestType.Memory) {
    gpuMemoryRequest && output.push(`Request: ${_parseMemoryValue(gpuMemoryRequest)}`);
    gpuMemoryLimit && output.push(`Limit: ${_parseMemoryValue(gpuMemoryLimit)}`);
  }
  return output;
}

function _parseCpuCoreRequest(computeSpec: ComputeAssetSpec): string[] {
  const output: string[] = [];
  const { cpuCoreRequest, cpuCoreLimit } = computeSpec;
  _hasValue(cpuCoreRequest) && output.push(`Request: ${cpuCoreRequest} Cores`);
  _hasValue(cpuCoreLimit) && output.push(`Limit: ${cpuCoreLimit} Cores`);
  return output;
}

function _parseCpuMemory(computeSpec: ComputeAssetSpec): string[] {
  const { cpuMemoryRequest, cpuMemoryLimit } = computeSpec;
  const output: string[] = [];

  cpuMemoryRequest && output.push(`Request: ${_parseMemoryValue(cpuMemoryRequest)}`);
  cpuMemoryLimit && output.push(`Limit: ${_parseMemoryValue(cpuMemoryLimit)}`);

  return output;
}

function _parseExtendedResourcesToDetailsContent(extendedResources: ExtendedResource[]): string[] {
  const output: string[] = [];
  return extendedResources.reduce((acc, eRes) => {
    const { quantity, resource } = eRes;
    acc.push(`Resource: ${resource}`);
    acc.push(`Quantity: ${quantity}`);
    acc.push(LINE_BREAK);
    return acc;
  }, output);
}

function _parseMemoryValue(memoryValue: string): string {
  const unit = memoryValue?.charAt(memoryValue.length - 1);
  const amount = memoryValue.slice(0, memoryValue.length - 1);
  switch (unit) {
    case "M":
      return `${amount} MB`;
    case "G":
      return `${amount} GB`;
    default:
      return memoryValue;
  }
}

function _dataSourceRawToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  switch (ds.meta.kind) {
    case AssetKind.Pvc:
      return _pvcToDetails(ds);
    case AssetKind.Nfs:
      return _nfsToDetails(ds);
    case AssetKind.HostPath:
      return _hostPathToDetails(ds);
    case AssetKind.Git:
      return _gitToDetails(ds);
    case AssetKind.S3:
      return _s3ToDetails(ds);
    case AssetKind.ConfigMap:
      return _configMapToDetails(ds);
    case AssetKind.SecretVolume:
      return _secretVolumeToDetails(ds);
    default:
      return [];
  }
}

const readOnlyTitle = "Prevent data modification (the data will be mounted with read-only permissions)";

function _pvcToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const pvcSpec: PVCAssetSpec = ds.spec.pvc as PVCAssetSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  pvcSpec.claimInfo?.storageClass &&
    itemDetails.push({ title: "Storage class", content: [pvcSpec.claimInfo.storageClass] });

  pvcSpec.claimInfo?.accessModes &&
    itemDetails.push({ title: "Access modes", content: _pvcAccessModesValue(pvcSpec.claimInfo.accessModes) });

  pvcSpec.claimInfo?.size && itemDetails.push({ title: "Claim size", content: [pvcSpec.claimInfo.size + "B"] });

  pvcSpec.claimInfo?.volumeMode && itemDetails.push({ title: "Volume mode", content: [pvcSpec.claimInfo.volumeMode] });

  pvcSpec.path && itemDetails.push({ title: "Container path", content: [pvcSpec.path] });

  pvcSpec.readOnly && itemDetails.push({ title: readOnlyTitle, content: ["Enabled"] });

  return itemDetails;
}

function _pvcAccessModesValue(accessModes: PvcAccessModes): string[] {
  const mapper = {
    readWriteOnce: "Read-write by one node",
    readOnlyMany: "Read-only by many nodes",
    readWriteMany: "Read-write by many nodes",
  };

  const activeAccessModes = [];
  accessModes.readWriteOnce && activeAccessModes.push(mapper.readWriteOnce);
  accessModes.readOnlyMany && activeAccessModes.push(mapper.readOnlyMany);
  accessModes.readWriteMany && activeAccessModes.push(mapper.readWriteMany);

  return [activeAccessModes.join(", ")];
}

function _nfsToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const nfsSpec: NFSAssetSpec = ds.spec.nfs as NFSAssetSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  nfsSpec.server && itemDetails.push({ title: "NFS server (host name or host IP)", content: [nfsSpec.server] });
  nfsSpec.path && itemDetails.push({ title: "NFS path", content: [nfsSpec.path] });
  nfsSpec.mountPath && itemDetails.push({ title: "Container path", content: [nfsSpec.mountPath] });
  nfsSpec.readOnly && itemDetails.push({ title: readOnlyTitle, content: ["Enabled"] });
  return itemDetails;
}

function _hostPathToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const hostPathSpec: HostPathSpec = ds.spec.hostPath as HostPathSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  hostPathSpec.path && itemDetails.push({ title: "Host path", content: [hostPathSpec.path] });
  hostPathSpec.mountPath && itemDetails.push({ title: "Container path", content: [hostPathSpec.mountPath] });
  hostPathSpec.readOnly && itemDetails.push({ title: readOnlyTitle, content: ["Enabled"] });

  return itemDetails;
}

function _gitToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const gitSpec: GitAssetSpec = ds.spec.git as GitAssetSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  gitSpec.repository && itemDetails.push({ title: "Repository URL", content: [gitSpec.repository] });
  gitSpec.revision && itemDetails.push({ title: "Revision", content: [gitSpec.revision] });
  gitSpec.branch && itemDetails.push({ title: "Branch", content: [gitSpec.branch] });
  gitSpec.path && itemDetails.push({ title: "Container path", content: [gitSpec.path] });
  return itemDetails;
}

function _s3ToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const s3Spec: S3AssetSpec = ds.spec.s3 as S3AssetSpec;
  const itemDetails = [];
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  s3Spec.url && itemDetails.push({ title: "S3 service URL", content: [s3Spec.url] });
  s3Spec.bucket && itemDetails.push({ title: "Bucket name", content: [s3Spec.bucket] });
  s3Spec.path && itemDetails.push({ title: "Container path", content: [s3Spec.path] });
  return itemDetails;
}

function _configMapToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const configMapSpec: ConfigMapAssetSpec = ds.spec.config_map as ConfigMapAssetSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  configMapSpec.configMap && itemDetails.push({ title: "ConfigMap name", content: [configMapSpec.configMap] });
  configMapSpec.mountPath && itemDetails.push({ title: "Container path", content: [configMapSpec.mountPath] });
  return itemDetails;
}

function _secretVolumeToDetails(ds: DatasourceListResponseEntry): TItemDetail[] {
  const itemDetails = [];
  const secretSpec: SecretAssetSpec = ds.spec.secret as SecretAssetSpec;
  ds.meta.description && itemDetails.push({ title: "Description", content: [ds.meta.description] });
  secretSpec.mountPath && itemDetails.push({ title: "Container path", content: [secretSpec.mountPath] });
  return itemDetails;
}

function getHybridWorkspaceModel(unifiedWorkload: UnifiedWorkload): IUIWorkspaceCreationRequest {
  return {
    name: unifiedWorkload.meta?.name || "",
    projectId: unifiedWorkload.meta?.projectId || "",
    clusterId: unifiedWorkload.meta?.clusterId || "",
    spec: {
      command: unifiedWorkload.spec?.worker?.command,
      args: unifiedWorkload.spec?.worker?.args,
      image: unifiedWorkload.spec?.worker?.image,
      imagePullPolicy: unifiedWorkload.spec?.worker?.imagePullPolicy,
      workingDir: unifiedWorkload.spec?.worker?.workingDir,
      createHomeDir: unifiedWorkload.spec?.worker?.createHomeDir,
      nodeType: unifiedWorkload.spec?.worker?.nodeType,
      nodePools: unifiedWorkload.spec?.worker?.nodePools,
      podAffinity: unifiedWorkload.spec?.worker?.podAffinity,
      tty: unifiedWorkload.spec?.worker?.tty,
      stdin: unifiedWorkload.spec?.worker?.stdin,
      environmentVariables: unifiedWorkload.spec?.worker?.environmentVariables,
      annotations: unifiedWorkload.spec?.worker?.annotations,
      labels: unifiedWorkload.spec?.worker?.labels,
      tolerations: unifiedWorkload.spec?.worker?.tolerations,
      terminateAfterPreemption: unifiedWorkload.spec?.worker?.terminateAfterPreemption,
      autoDeletionTimeAfterCompletionSeconds: unifiedWorkload.spec?.worker?.autoDeletionTimeAfterCompletionSeconds,
      backoffLimit: unifiedWorkload.spec?.worker?.backoffLimit,
      connections: getConnectionsField(
        unifiedWorkload.spec?.worker?.ports || [],
        unifiedWorkload.spec?.worker?.exposedUrls || [],
        unifiedWorkload.spec?.worker?.relatedUrls || [],
      ),
      priorityClass: unifiedWorkload.spec?.worker?.priorityClass,
      compute: getComputeField(unifiedWorkload.spec?.worker?.compute),
      storage: getStorageField(unifiedWorkload.spec?.worker?.storage),
      security: getSecurityField(unifiedWorkload.spec?.worker?.security),
    },
  };
}

function getHybridTrainingModel(unifiedWorkload: UnifiedWorkload): IUITrainingCreationRequest {
  // rotemTODO: REQUIRES CHANGES
  return {
    name: unifiedWorkload.meta?.name || "",
    projectId: unifiedWorkload.meta?.projectId || "",
    clusterId: unifiedWorkload.meta?.clusterId || "",
    spec: {
      command: unifiedWorkload.spec?.worker?.command,
      args: unifiedWorkload.spec?.worker?.args,
      image: unifiedWorkload.spec?.worker?.image,
      imagePullPolicy: unifiedWorkload.spec?.worker?.imagePullPolicy,
      workingDir: unifiedWorkload.spec?.worker?.workingDir,
      createHomeDir: unifiedWorkload.spec?.worker?.createHomeDir,
      nodeType: unifiedWorkload.spec?.worker?.nodeType,
      nodePools: unifiedWorkload.spec?.worker?.nodePools,
      podAffinity: unifiedWorkload.spec?.worker?.podAffinity,
      tty: unifiedWorkload.spec?.worker?.tty,
      stdin: unifiedWorkload.spec?.worker?.stdin,
      environmentVariables: unifiedWorkload.spec?.worker?.environmentVariables,
      annotations: unifiedWorkload.spec?.worker?.annotations,
      labels: unifiedWorkload.spec?.worker?.labels,
      tolerations: unifiedWorkload.spec?.worker?.tolerations,
      terminateAfterPreemption: unifiedWorkload.spec?.worker?.terminateAfterPreemption,
      autoDeletionTimeAfterCompletionSeconds: unifiedWorkload.spec?.worker?.autoDeletionTimeAfterCompletionSeconds,
      backoffLimit: unifiedWorkload.spec?.worker?.backoffLimit,
      connections: getConnectionsField(
        unifiedWorkload.spec?.worker?.ports || [],
        unifiedWorkload.spec?.worker?.exposedUrls || [],
        unifiedWorkload.spec?.worker?.relatedUrls || [],
      ),
      priorityClass: unifiedWorkload.spec?.worker?.priorityClass,
      compute: getComputeField(unifiedWorkload.spec?.worker?.compute),
      storage: getStorageField(unifiedWorkload.spec?.worker?.storage),
      security: getSecurityField(unifiedWorkload.spec?.worker?.security),
    },
  };
}

function getComputeField(compute: ComputeFields | undefined | null): ComputeFields | undefined {
  if (!compute) return {};

  return {
    gpuDevicesRequest: compute.gpuDevicesRequest,
    gpuRequestType: compute.gpuRequestType,
    gpuPortionRequest: compute.gpuPortionRequest,
    gpuPortionLimit: compute.gpuPortionLimit,
    gpuMemoryRequest: compute.gpuMemoryRequest,
    gpuMemoryLimit: compute.gpuMemoryLimit,
    migProfile: compute.migProfile,
    cpuCoreRequest: compute.cpuCoreRequest,
    cpuCoreLimit: compute.cpuCoreLimit,
    cpuMemoryRequest: compute.cpuMemoryRequest,
    cpuMemoryLimit: compute.cpuMemoryLimit,
    largeShmRequest: compute.largeShmRequest,
    extendedResources: compute.extendedResources,
  };
}

function getStorageField(storage: StorageFields | undefined | null): IUIStorageFields {
  if (!storage) return {};
  const uiStorage: IUIStorageFields = {};
  uiStorage.pvc = storage.pvc
    ?.filter((p) => p.existingPvc)
    .map((pvc: PvcInstance) => ({
      id: makeId(),
      type: AssetKind.Pvc,
      ...pvc,
    }));

  uiStorage.volumes = storage.pvc?.filter((p) => !p.existingPvc).map((pvc: PvcInstance) => pvcToUIVolume(pvc));

  uiStorage.nfs = storage.nfs?.map((nfs: NfsInstance) => ({
    id: makeId(),
    type: AssetKind.Nfs,
    ...nfs,
  }));

  uiStorage.hostPath = storage.hostPath?.map((hostPath: HostPathInstance) => ({
    id: makeId(),
    type: AssetKind.HostPath,
    ...hostPath,
  }));

  uiStorage.git = storage.git?.map((git: GitInstance) => ({
    id: makeId(),
    type: AssetKind.Git,
    ...git,
  }));

  uiStorage.s3 = storage.s3?.map((s3: S3Instance) => ({
    id: makeId(),
    type: AssetKind.S3,
    ...s3,
  }));

  uiStorage.configMapVolume = storage.configMapVolume?.map((configMap: ConfigMapInstance) => ({
    id: makeId(),
    type: AssetKind.ConfigMap,
    ...configMap,
  }));

  uiStorage.secretVolume = storage.secretVolume?.map((secret: SecretInstance) => ({
    id: makeId(),
    type: AssetKind.SecretVolume,
    ...secret,
  }));

  return uiStorage;
}

function pvcToUIVolume(pvc: PvcInstance): IUIVolume {
  return {
    claimName: "claim-" + makeId(10),
    path: pvc.path || "",
    claimInfo: pvc.claimInfo || {},
    ephemeral: pvc.ephemeral || false,
  };
}

function getStorageFieldFromUI(storage: IUIStorageFields): StorageFields {
  const storageFields: StorageFields = {};

  storageFields.pvc = storage.pvc?.map((pvc: IUIPvcInstance) => ({
    ...omit(pvc, ["id", "type"]),
  }));

  if (storage.volumes?.length) {
    if (storageFields.pvc) storageFields.pvc = [...storageFields.pvc, ...storage.volumes];
    else storageFields.pvc = storage.volumes;
  }

  storageFields.nfs = storage.nfs?.map((nfs: IUINfsInstance) => ({
    ...omit(nfs, ["id", "type"]),
  }));

  storageFields.hostPath = storage.hostPath?.map((hostPath: IUIHostPathInstance) => ({
    ...omit(hostPath, ["id", "type"]),
  }));

  storageFields.git = storage.git?.map((git: IUIGitInstance) => ({
    ...omit(git, ["id", "type"]),
  }));

  storageFields.s3 = storage.s3?.map((s3: IUIS3Instance) => ({
    ...omit(s3, ["id", "type"]),
  }));

  storageFields.configMapVolume = storage.configMapVolume?.map((configMap: IUIConfigMapInstance) => ({
    ...omit(configMap, ["id", "type"]),
  }));

  storageFields.secretVolume = storage.secretVolume?.map((secret: IUISecretVolumeInstance) => ({
    ...omit(secret, ["id", "type"]),
  }));

  return storageFields;
}

function getSecurityField(security: SecurityFlatFields | undefined | null): SecurityFlatFields | undefined {
  if (!security) return {};

  return {
    uidGidSource: security.uidGidSource,
    capabilities: security.capabilities,
    seccompProfileType: security.seccompProfileType,
    runAsNonRoot: security.runAsNonRoot,
    readOnlyRootFilesystem: security.readOnlyRootFilesystem,
    runAsGid: security.runAsGid,
    runAsUid: security.runAsUid,
    supplementalGroups: security.supplementalGroups,
    allowPrivilegeEscalation: security.allowPrivilegeEscalation,
    hostIpc: security.hostIpc,
    hostNetwork: security.hostNetwork,
  };
}

function getConnectionsField(ports: Port[], exposedUrls: ExposedUrl[], relatedUrls: RelatedUrl[]): IUIConnection[] {
  const connections: IUIConnection[] = [];
  ports.forEach((port: Port) => {
    connections.push(convertPortToUiConnection(port));
  });

  exposedUrls.forEach((url: ExposedUrl) => {
    connections.push(convertExposedUrlToUiConnection(url));
  });

  relatedUrls.forEach((url: RelatedUrl) => {
    connections.push(convertRelatedUrlToUiConnection(url));
  });

  return connections;
}

function convertPortToUiConnection(port: Port): IUIConnection {
  return {
    id: makeId(),
    name: port.toolName || "",
    toolType: port.toolType as ToolType,
    containerPort: port.container || undefined,
    isCustomPort: !!port.external,
    nodePort: port.external,
    connectionType: InternalConnectionType.NodePort,
  };
}

function convertExposedUrlToUiConnection(url: ExposedUrl): IUIConnection {
  return {
    id: makeId(),
    name: url.name || url.toolName || "",
    toolType: url.toolType as ToolType,
    connectionType: InternalConnectionType.ExternalUrl,
    isCustomUrl: !!url.url,
    externalUrl: url.url,
    authorizedUsers: url.authorizedUsers,
    authorizedGroups: url.authorizedGroups,
    containerPort: url.container || undefined,
  };
}

function convertRelatedUrlToUiConnection(url: RelatedUrl): IUIConnection {
  return {
    id: makeId(),
    toolType: url.type as ToolType,
    name: url.name || "",
    externalUrl: url.url,
    connectionType: InternalConnectionType.ExternalUrl,
  };
}

function setWorkspaceUIDefaults(
  uiModel: IUIWorkspaceCreationRequest,
  isSSO: boolean,
  policy?: UnifiedPolicyInfoPerReplicaType | null,
): IUIWorkspaceCreationRequest {
  // checking specific values with the policy before setting
  let imagePullPolicyUIDefault: ImagePullPolicy | null = ImagePullPolicy.IfNotPresent;
  if (Array.isArray(policy?.rules?.imagePullPolicy?.options)) {
    if (!policy.rules.imagePullPolicy.options.map((o) => o.value).includes(ImagePullPolicy.IfNotPresent)) {
      imagePullPolicyUIDefault = null;
    }
  }

  let uidGidSourceDefault: UidGidSource | null = isSSO ? UidGidSource.FromIdpToken : UidGidSource.FromTheImage;
  if (Array.isArray(policy?.rules?.security?.uidGidSource?.options)) {
    if (!policy.rules.security.uidGidSource.options.map((o) => o.value).includes(uidGidSourceDefault)) {
      uidGidSourceDefault = null;
    }
  }

  return {
    ...uiModel,
    spec: {
      ...uiModel.spec,

      image: fallbackDefaultIfNullOrUndefined(uiModel.spec.image, ""),
      imagePullPolicy: fallbackDefaultIfNullOrUndefined(uiModel.spec.imagePullPolicy, imagePullPolicyUIDefault),
      environmentVariables: fallbackDefaultIfNullOrUndefined(uiModel.spec.environmentVariables, []),
      autoDeletionTimeAfterCompletionSeconds: fallbackDefaultIfNullOrUndefined(
        uiModel.spec?.autoDeletionTimeAfterCompletionSeconds,
        60 * 60 * 24 * 30, // 30 days in seconds
      ),
      backoffLimit: fallbackDefaultIfNullOrUndefined(uiModel.spec?.backoffLimit, 6),
      connections: fallbackDefaultIfNullOrUndefined(uiModel.spec.connections, []),

      security: {
        ...uiModel.spec.security,
        uidGidSource: fallbackDefaultIfNullOrUndefined(uiModel.spec?.security?.uidGidSource, uidGidSourceDefault),
        capabilities: fallbackDefaultIfNullOrUndefined(uiModel.spec?.security?.capabilities, []),
      },
    },
  };
}

function setTrainingUIDefaults(
  uiModel: IUITrainingCreationRequest,
  isSSO: boolean,
  policy?: UnifiedPolicyInfoPerReplicaType | null,
): IUITrainingCreationRequest {
  // checking specific values with the policy before setting
  let imagePullPolicyUIDefault: ImagePullPolicy | null = ImagePullPolicy.IfNotPresent;
  if (Array.isArray(policy?.rules?.imagePullPolicy?.options)) {
    if (!policy.rules.imagePullPolicy.options.map((o) => o.value).includes(ImagePullPolicy.IfNotPresent)) {
      imagePullPolicyUIDefault = null;
    }
  }

  let uidGidSourceDefault: UidGidSource | null = isSSO ? UidGidSource.FromIdpToken : UidGidSource.FromTheImage;
  if (Array.isArray(policy?.rules?.security?.uidGidSource?.options)) {
    if (!policy.rules.security.uidGidSource.options.map((o) => o.value).includes(uidGidSourceDefault)) {
      uidGidSourceDefault = null;
    }
  }

  return {
    ...uiModel,
    spec: {
      ...uiModel.spec,

      image: fallbackDefaultIfNullOrUndefined(uiModel.spec.image, ""),
      imagePullPolicy: fallbackDefaultIfNullOrUndefined(uiModel.spec.imagePullPolicy, imagePullPolicyUIDefault),
      environmentVariables: fallbackDefaultIfNullOrUndefined(uiModel.spec.environmentVariables, []),
      autoDeletionTimeAfterCompletionSeconds: fallbackDefaultIfNullOrUndefined(
        uiModel.spec?.autoDeletionTimeAfterCompletionSeconds,
        60 * 60 * 24 * 30, // 30 days in seconds
      ),
      backoffLimit: fallbackDefaultIfNullOrUndefined(uiModel.spec?.backoffLimit, 6),
      connections: fallbackDefaultIfNullOrUndefined(uiModel.spec.connections, []),

      security: {
        ...uiModel.spec.security,
        uidGidSource: fallbackDefaultIfNullOrUndefined(uiModel.spec?.security?.uidGidSource, uidGidSourceDefault),
        capabilities: fallbackDefaultIfNullOrUndefined(uiModel.spec?.security?.capabilities, []),
      },
    },
  };
}

function getRelevantComputeFields(model: IUIWorkspaceCreationRequest): IUIWorkspaceSpec["compute"] {
  return {
    ...model.spec.compute,
  };
}

function getRelevantStorageFields(model: IUIWorkspaceCreationRequest): IUIWorkspaceSpec["storage"] {
  return model.spec.storage;
}

function getRelevantEnvironmentFields(model: IUIWorkspaceCreationRequest): IUIEnvrionmentFields {
  return {
    command: model.spec.command,
    args: model.spec.args,
    image: model.spec.image,
    imagePullPolicy: model.spec.imagePullPolicy,
    workingDir: model.spec.workingDir,
    createHomeDir: model.spec.createHomeDir,
    environmentVariables: model.spec.environmentVariables,
    connections: model.spec.connections,
    priorityClass: model.spec.priorityClass,
    security: model.spec.security,
  };
}

function combineWorkloadModelWithComputeDetails(
  initialWorkloadModel: IUIWorkspaceCreationRequest,
  computeFields: IUIWorkspaceSpec["compute"],
): IUIWorkspaceCreationRequest {
  return {
    ...initialWorkloadModel,
    spec: {
      ...initialWorkloadModel.spec,
      compute: computeFields,
    },
  };
}

function combineWorkloadModelWithStorageDetails(
  initialWorkloadModel: IUIWorkspaceCreationRequest,
  storageFields?: IUIStorageFields,
): IUIWorkspaceCreationRequest {
  return {
    ...initialWorkloadModel,
    spec: {
      ...initialWorkloadModel.spec,
      storage: {
        ...storageFields,
        volumes: initialWorkloadModel.spec.storage?.volumes,
      },
    },
  };
}
function combineWorkloadModelWithEnvironmentDetails(
  initialWorkloadModel: IUIWorkspaceCreationRequest,
  envFields: IUIEnvrionmentFields,
): IUIWorkspaceCreationRequest {
  return {
    ...initialWorkloadModel,
    spec: {
      ...initialWorkloadModel.spec,
      ...envFields,
    },
  };
}

function prepareWorkspaceFlatRequest(
  model: IUIWorkspaceCreationRequest,
  tokenData?: ITokenData,
): WorkspaceCreationRequest {
  const storage = getStorageFieldFromUI(model.spec.storage || {});
  const security = { ...model.spec.security };

  if (model.spec.security?.uidGidSource === UidGidSource.FromIdpToken) {
    security.runAsGid = tokenData?.gid;
    security.runAsUid = tokenData?.uid;
    security.supplementalGroups = tokenData?.supplementalGroups;
  }

  return {
    ...model,
    spec: {
      ...omit(model.spec, ["connections"]),
      ..._connectionsToFlatRequest(model.spec.connections),
      storage,
      security,
    },
  };
}

function prepareTrainingFlatRequest(model: IUITrainingCreationRequest, tokenData?: ITokenData): TrainingCreationRequest {
  const storage = getStorageFieldFromUI(model.spec.storage || {});
  const security = { ...model.spec.security };

  if (model.spec.security?.uidGidSource === UidGidSource.FromIdpToken) {
    security.runAsGid = tokenData?.gid;
    security.runAsUid = tokenData?.uid;
    security.supplementalGroups = tokenData?.supplementalGroups;
  }

  return {
    ...model,
    spec: {
      ...omit(model.spec, ["connections"]),
      ..._connectionsToFlatRequest(model.spec.connections),
      storage,
      security,
    },
  };
}

interface IURLAndPorts {
  exposedUrls: WorkspaceSpecSpec["exposedUrls"];
  ports: WorkspaceSpecSpec["ports"];
  relatedUrls: WorkspaceSpecSpec["relatedUrls"];
}

function _connectionsToFlatRequest(connections?: Array<IUIConnection>): IURLAndPorts {
  const urlsAndPorts: IURLAndPorts = {
    exposedUrls: [],
    ports: [],
    relatedUrls: [],
  };

  connections?.forEach((connection: IUIConnection) => {
    if (connection.connectionType === InternalConnectionType.NodePort) {
      const port: Port = {
        container: connection.containerPort,
        external: connection.nodePort,
        toolName: connection.name,
        toolType: connection.toolType,
        serviceType: InternalConnectionType.NodePort,
      };
      urlsAndPorts.ports && urlsAndPorts.ports.push(port);
    } else if (connection.connectionType === InternalConnectionType.ExternalUrl) {
      if (connection.toolType === ToolType.Wandb || connection.toolType === ToolType.Comet) {
        const relatedUrl: RelatedUrl = {
          name: connection.name,
          url: connection.externalUrl,
          type: connection.toolType,
        };
        urlsAndPorts.relatedUrls && urlsAndPorts.relatedUrls.push(relatedUrl);
      } else {
        const exposedUrl: ExposedUrl = {
          toolName: connection.name,
          toolType: connection.toolType,
          url: connection.externalUrl,
          container: connection.containerPort,
          authorizedUsers: connection.authorizedUsers,
          authorizedGroups: connection.authorizedGroups,
        };
        urlsAndPorts.exposedUrls && urlsAndPorts.exposedUrls.push(exposedUrl);
      }
    }
  });

  return urlsAndPorts;
}
